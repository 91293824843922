.back{
    height: 100%;
    width: 100%;
}

.back-card {
    position: relative;
    height: 100%;
    width: 100%;
}

.back-img {
    height: 100%;
    width: 100%;
}


.back-img img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    filter: brightness(50%)
}

.back-btn-wrapper {
    position: absolute;
    height: 10%;
    width: 100%;
    bottom: 5px;
    cursor: pointer;
}
.back-btn-wrapper Button{
    color: white;
    border: 1px solid white;
}

.back-details{
    height: 90%;
    width: 100%;
    position: absolute;
    top: 0;
    /* display: flex;
    flex-direction: column;
    justify-content: space-around; */
}

.back-title{
    position: relative;
    top: 0.5;
    height: 10%;
    font-size: .9em;
    padding-bottom: 5px;
}

.back-title h1{
    margin-block-start: 0;
    margin-block-end: 0;
}

.back-mini{
    position: relative;
    height: 85%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}

.mini-content:nth-child(odd){
    background-color: rgb(191 185 185 / 34%)
}

.mini-content {
    width: 98%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1%;
}

.mini-title{
    width: 60%;
    padding-left: 2px;
    text-align: start;
}

.time-cost-icon {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    background-color: #bac3c7e6;
    border-radius: 3px;
    margin-bottom: 5px;
    height: 30px;
    align-items: center;
}

.back-services {
    font-weight: bold;
    padding-left: 2px;
    font-size: 1.15rem;
    width: 50%;
    text-align: left;
    padding-left: 6px;
    height: inherit;
    display: flex;
    align-items: center;
}

.cost-time-wrapper{
    display: flex;
    font-size: 1.15rem;
    width: 50%;
    justify-content: space-around;
}

.time-cost{
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 2px;
}

.icon-cost, .icon-time{
    width: 25%;
    color: #71576ad1;    
    font-size: 1.3em;
}



@media screen and (max-width: 600px) {
    .mini-content {
        height: 32px;
    }
    
}