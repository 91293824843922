.about {
    --main-color: #f1c50e;
    --info-box-margin: 3%;
    display: flex;
    justify-content: center;
    height: 1000px;
    background-color: #f6ebe7;
    color: black;
    padding: 20px 10px ;
    position: relative;
    top: 0;
}

 .about .title{
    display: none;
}

.about #contents{
    height: 100%;
    max-width:960px;
    display: block;
}

.content-wrapper{
    height: 100%;
    margin-left:var(--info-box-margin);
    margin-right:auto;
}


.mini-circle{
    position: relative;
    height: 0;
    max-width: 15%;
    padding-bottom: 15%;
    border: 40px solid var(--main-color);
    border-radius: 50%;
}

.story-box{
    position: relative;
    min-height: 45%;
    max-width: 60%;
    top: -14%;
    left: 12%;
    border: 5px solid var(--main-color);
    background-color: white;
    box-shadow: 145px 150px var(--main-color);
}

.web-title{
    font-family: 'Playfair Display', serif;
}

.web-title h2{
    font-size: 3.75rem;
    margin: 28px 0 0;
    margin-block-start: 0;
}

.web-title h5{
    margin-block-end: 0;;
    font-size: 1.5rem;
}

.story {
    font-size: 1.5rem;
}

.one{
    position: relative;
    font-family: 'Open Sans', sans-serif;
    padding: 10px;
    padding-bottom: 10%;
}

.web-headshot {
    position: relative;
    height: 75%;
    width: 45%;
    border: 20px solid white;
    position: absolute;
    left: 70%;
    top: 85%;
}

.web-headshot img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}


.team {
    position: relative;
    height: 40%;
}

.sammy {
    height: 100%;
    position: relative;
}

.headshot-name {
    display: none;
}

.headshot, .name, .name-text{
    display: none;
}

.web-name{
    position: relative;
    background-color: var(--main-color);
    display: flex;
    align-items: center;
    margin: 0 auto;
    bottom: 5%;
    height: 20%;
}

.web-name-text{
    float: right;
    font-size: 2rem;
    font-family: 'Playfair Display';
    font-weight: bold;
    color: black;
}


.sammy .info{
    display: block;
    position: relative;
    border:5px solid var(--main-color);
    width: 40%;
    max-height: 80%;
    right:var(--info-box-margin);
    bottom: 15%;
    font-family: 'Playfair Display', Courier, monospace;
    font-size: 1.15rem;
    background-color: white;
    padding-bottom: 5px;
}

.info h3{
    margin-block-end: 0;
    padding-bottom: 5px;
}
@media screen and (min-width: 1200px) {
    .story-box{
        border: 10px solid var(--main-color);
        max-height: 45%;
        position: relative;
    }

    .story{
        font-size: 1.5rem;
    }

    .web-headshot{
        position: absolute;
        width: 40%;
        top: 75%;
    }

}

@media screen and (max-width: 600px) {
    .mini-circle, .web-title, .web-headshot, .web-headshot img, .web-name, .web-name-text, .web-title h5, .web-title h2{
        display: none;
    }
    .about {
        --name-height:80px;
        --headshot-area: 250px;
        --name-bottom-margin: -20%;
       width: auto;
       height: auto;
       margin-left: auto;
       margin-right: auto;
       display: block;
       padding: 0 15px 15% 0;
       background-color: #000000c7;
       color: white;
    }

    .about #contents{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        height: auto;
        max-width: 100%;
    }

    #contents, .content-wrapper {
        height: auto;
    }

    .about .title{
        display: block;
        position: relative; 
        height: 50px;
        font-size: 2.5rem;
        font-family: 'Playfair Display', serif !important;
        font-weight: bold;
        padding: 5px 5px 10px;
    }
    
    .story-box {
        height: auto;
        position: relative;
        min-height: inherit;
        max-width: 99% !important;
        top: 0;
        left: 0;
        background-color:inherit;
        box-shadow: 45px 150px var(--main-color) !important;
    }

    .one{
        padding: 5px;
        padding-top: 5px;
        font-family: 'Playfair Display', serif !important;
        font-size: 1.25rem;
     }

     .team {
        margin-top: 10%;
        padding-top: 20px;
    }

    .sammy{
        height: auto;
        position: inherit;
    }
    
    .headshot-name {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
    }


    .headshot {
        display: block;
        height: var(--headshot-area);
        width: var(--headshot-area);
    }

    .headshot img{
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    .name{
        position: absolute;
        bottom: var(--name-bottom-margin);
        background-color: var(--main-color);
        height: var(--name-height);
        width: 75%;
        border-radius: 5%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .name-text{
        display: block;
        float: right;
        font-size: 2rem;
        font-family: 'Playfair Display';
        font-weight: bold;
        color: black;
    }

    .sammy .info{
        display: block;
        position: relative;
        margin-top: 20%;
        padding: 10px;
        font-family: 'Playfair Display', serif !important;
        font-size: 1.25rem;
        width: auto !important;
        max-height: none;
        right: inherit;
        background-color: #00000029;
        color: white;
    }

    .info h2{
        display: none;
    }

    .info h3{
        margin-block-start: 0;
    }
    
}

@media screen and (max-width:768px) {
    .story-box {
        max-width: 70%;
        box-shadow: 117px 150px var(--main-color)
    }
    .web-headshot {
        height: 68%;
        top: 85%;
        left:65%;
    }

    .sammy .info {
        width: 50%;

    }
    
}

@media screen and (min-width:1199px) {
    .web-headshot {
        top: 85%;
    }
}