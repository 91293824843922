.front{
    height: 100%;
    width: 100%;
}

.front-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: black;
    color: white;
    max-height: 100%;
}

.card-media {
    height: 200px;
    width: 200px;
}

.image-wrapper{
    width: 100%;
    height: 100%;
}

.image-wrapper img{
    object-fit: cover;
    width: 90%;
    height: 90%;
    border-radius: 50%;
    border: 10px solid bisque;
}

.details-wrapper{
    margin-bottom: 5px;
}

.btn-wrapper {
    position: relative;
    bottom: 5px;
    cursor: pointer;
}

.btn-wrapper Button{
    color: white;
    border: 1px solid white;
}

.media {
    display: none;
}


@media screen and (max-width: 600px) {

    .media {
        position: absolute;
        left: 50%;
        top: 0%;
        width: 50%;
        height: 10%;
        display: flex;
        justify-content: flex-end;
    }


}

/* .CardActions{
    justify-content: center;
}

.CardActionArea{
    height: 90%;
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-end !important;
    align-items: flex-start !important;
}

.CardMedia{
    position: absolute;
    height: 75%;
    top: 0;
} */