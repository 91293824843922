.testimonials {
  height: 580px;
  position: relative;
  background-color: #f19a0e;
  width: 100%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 30px 0;
}

.testimonial-wrapper{
  position: relative;
  height: 100%;
  width: 960px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

}

.react-multi-carousel-list {
  position: relative;
  width: 100%;
  height: 80%;
  bottom: 8%;
}

.react-multi-carousel-track {
  
}

.react-multi-carousel-item  {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.custom-dot-list-style button{
  border: none;
  background: rgb(68, 127, 255);
}

.react-multi-carousel-dot.react-multi-carousel-dot--active button{
  background: rgb(124, 255, 68) !important;
}
 .react-multiple-carousel__arrow--left {
  left: 0 !important;

}
.react-multiple-carousel__arrow--right {
  right: 0 !important;
}

.testimonial-title{
  position: relative;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  font-family: 'Playfair Display';
}

.testimonial-title .main-title{
  font-size: 3rem;
  font-weight: bold;
}

.testimonial-title .sub-title{
  font-size: 3rem;
  text-transform: capitalize;
}

@media screen and (max-width: 600px) {
  .testimonial-title .main-title{
    font-size: 2rem;
    font-weight: bold;
  }
  
  .testimonial-title .sub-title{
    font-size: 2rem;
    text-transform: capitalize;
    width: 80%;
    margin-left: 10%;
  }

  .testimonials{
    padding: 5px 10px 0;
  }
  .react-multi-carousel-list {
    bottom: 5%;
  }
}

@media screen and (max-width: 768px) {
  .testimonials{
    padding: 0;
  }
  .testimonial-wrapper{
    width: 99%;
    padding: 0;
  }

}

@media screen and (max-width:640px) {
  .testimonials{
    padding-top: 20px;
  }
}