.services {
    min-height:60vh;
    background-color: black;
    height: auto;
    color:white;
    padding-bottom: 10px;
}

.service-cards{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 2.5% 5px 0 5px;
}

.our-services {
    padding-top: 2.5%;
}
.our-services h1{
    margin-block-start: 0;
}

@media screen and (max-width: 600px) {
    .services {
        height: auto;
        margin-bottom: 10px;
        background-color: black;
        color: white;
    }

    .our-services {
        padding-top: 10%;
    }

    .service-cards{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}