.home {
    height: 80vh;
    background-image: url('../../assets/home/background_img.png');
    background-repeat:no-repeat;
    background-position:center;
    background-size:cover;
        position: relative;
}

.text {
    position: absolute;
    width: 100%;
    bottom: 40%;
    color: azure;
    text-align: center;
    z-index: 1;
}

.menu{
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    bottom: 30%;
    z-index: 1;
}

#home-services{
    border: 1px solid white;
    text-align: center;
    color: white;
    font-weight: bold;
    margin-right: 10px;
}

#home-phone{
    border: 1px solid white;
    margin-left: 10px;
    text-align: center;
    color: white;
    font-weight: bold;
}

.home .facial-img{
    filter: brightness(60%);
    position: relative;
    width:100%;
    height:100%;
    object-fit: cover;
    overflow: hidden;
}