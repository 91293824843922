.design-circles{
    margin-top: 45px;
}

.address-display{
    width: 100%;
    text-decoration:underline;
    text-decoration-color: white;
}

.street-address {
    position: absolute;
    margin-left: 5px !important;
}

.city-state-zip{
    position: absolute;
    margin-top: 20px;
    margin-left: 5px;
}

@media screen and (max-width:640px) {
    .DetailsBarWrapper .ContactsWrapper .svg-inline--fa:not(.fa-phone){
        padding: 0;
    }
    .DetailsBarWrapper .ContactsWrapper .address-display .street-address {
        margin-left: 1% !important;
    }

    .design-circles {
        margin-top: 35px;
    }
}
