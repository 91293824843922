.contact {
    position: relative;
    height: 100%;
    width:100%;
}

.page-wrapper{
    width: 100% !important;
    height: 100% !important;
    color:white;
}

.form-container{
    height: 100%;
}

@media screen and (max-width:800){
    .form-container{
        width: 100% !important;
    }
    
}
