
.card{
    /* box-shadow: 0 0 5px 5px rgba(0, 6, 0, 0.5);
    background-color: rgba(245, 245, 245, 8.973); */
    /* transform-style: preserve-3d; */
    /* transition: 150ms; */
    /* transform: perspective(1000px) rotateY(var(--rotate-y, 0)) translateY(var(--translate-y, 0)); */
    width: 300px;
    height: 350px;
    margin: 20px 0;
}

.card-media{
    height: 100%;
}


.card-flip {
    position: relative;
    background-color: #121313c9;
    overflow: hidden;
    transition: .75s ease;
    width: 100%;
    height: 0;
    bottom: 100%;
}
  

@media screen and (max-width: 600px) {
    .card{
        height: 400px;
        width: 100%;
        margin-bottom: 3px;
    }

}
